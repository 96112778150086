<template>
  <div
    tabindex="0"
    class="btn add-btn"
    :active="active || null"
    @keyup="toggleActive"
    @click="toggleActive"
  >
    <svg>
      <use :href="iconUrlWithHash" />
    </svg>
    Add to myLinks
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default {
  name: "BookmarkLink",
  replace: false,
  props: {
    iconUrl: {
      type: String,
      default: "/static/img/svg/custom-icons.svg",
    },
    endpoint: {
      type: String,
    },
    link: {
      type: String,
    },
    title: {
      type: String,
    },
    isActive: {
      type: Boolean,
    },
  },

  data() {
    return {
      active: this.isActive,
    };
  },
  computed: {
    iconUrlWithHash() {
      if (!this.active) {
        return `${this.iconUrl}#heart`;
      } else {
        return `${this.iconUrl}#heart-fill`;
      }
    },
  },
  methods: {
    toggleActive(event, persist = true) {
      if (event.code !== "Enter" && event.code !== undefined) {
        return;
      }

      this.active = !this.active;
      if (persist) {
        this.persistBookmark(event);
      }
    },
    persistBookmark() {
      const link = { url: this.link, title: this.title };
      if (!this.active) {
        this.$store.dispatch("deleteLink", { link });
      } else {
        this.$store.dispatch("putLink", { link });
      }
    },
  },
};
</script>
