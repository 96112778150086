<template>
  <div>
    <div class="mycorner--content--box">
      <div class="mycorner--content--title">
        <h4 class="mb-0">
          <svg class="match-h4">
            <use href="/static/img/svg/custom-icons.svg#heart-fill"></use>
          </svg>
          myApps
        </h4>
        <div v-if="apps.length > 1" class="form-check form-switch">
          <input
            id="enableSortingApps"
            v-model="enabledApps"
            class="form-check-input"
            type="checkbox"
            role="switch"
          />
          <label class="form-check-label" for="enableSortingApps">Organize</label>
        </div>
      </div>
      <div class="row">
        <p v-if="apps.length === 0">You haven’t added any Apps yet.</p>
        <draggable
          :list="apps"
          :disabled="!enabledApps"
          item-key="launch_url"
          handle=".handle"
          ghost-class="ghost"
          class="row gx-3"
          @start="dragging = true"
          @end="saveOrderApps"
        >
          <template #item="{ element }">
            <div class="col-6 gy-3">
              <div v-if="enabledApps" class="mycorner--apps-logo handle">
                <div class="row g-1 align-items-center">
                  <div class="col-auto">
                    <svg>
                      <use
                        href="/static/img/svg/custom-icons.svg#draggable"
                      ></use>
                    </svg>
                  </div>
                  <div class="col">
                    <img
                      :src="element.icon.file"
                      class="img-fluid"
                      :alt="element.title"
                    />
                  </div>
                </div>
                <div class="col-auto appDelete" @click="deleteAppLink(element)">
                  <svg class="app-delete" aria-labelledby="svgTitle" role="img">
                    <title id="svgTitle">Remove Link</title>
                    <use href="/static/img/svg/custom-icons.svg#times-circle"></use>
                  </svg>
                </div>
              </div>
              <a
                v-else
                :href="element.launch_url"
                target="_blank"
                rel="noopener noreferrer"
                class="mycorner--apps-logo"
              >
                <div class="d-flex align-items-center">
                  <img
                    :src="element.icon.file"
                    class="img-fluid"
                    :alt="element.title"
                  />
                </div>
              </a>
            </div>
          </template>
        </draggable>
      </div>
      <a href="/apps/" class="manageApps">
        View All Apps
        <svg>
          <use href="/static/img/svg/custom-icons.svg#chevron"></use>
        </svg>
      </a>
    </div>

    <div class="mycorner--content--box mt-4">
      <div class="mycorner--content--title">
        <h4 class="mb-0">
          <svg class="match-h4">
            <use href="/static/img/svg/custom-icons.svg#heart-fill"></use>
          </svg>
          myLinks
        </h4>
        <div v-if="links.length > 0" class="form-check form-switch">
          <input
            id="enableSortingLinks"
            v-model="enabledPageLinks"
            class="form-check-input"
            type="checkbox"
            role="switch"
          />
          <label class="form-check-label" for="enableSortingLinks">Organize</label>
        </div>
      </div>
      <div class="row">
        <p v-if="links === 0">You haven’t added any myModerna links yet.</p>
        <draggable
          v-else
          :list="links"
          :disabled="!enabledPageLinks"
          item-key="url"
          handle=".handle"
          ghost-class="ghost"
          class="col mt-2"
          @start="dragging = true"
          @end="saveOrderPageLinks"
        >
          <template #item="{ element }">
            <div class="mycorner--apps-pageLink" :class="{hasHandle : enabledPageLinks}">
              <div v-if="enabledPageLinks" class="row g-0 justify-content-between align-items-start w-100">
                <div class="col handle">
                  <div class="row g-0">
                    <div class="col-auto">
                      <svg aria-labelledby="svgTitle" role="img">
                        <title>Reorganize Link</title>
                        <use
                          href="/static/img/svg/custom-icons.svg#draggable"
                        ></use>
                      </svg>
                    </div>
                    <div class="col">
                      {{ element.title }}
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <svg class="custom-link-delete" @click="deleteCustomLink(element)" aria-labelledby="svgTitle" role="img">
                    <title>Remove Link</title>
                    <use href="/static/img/svg/custom-icons.svg#close"></use>
                  </svg>
                </div>
              </div>
              <a
                v-else
                :href="element.url"
                target="_blank"
                rel="noopener noreferrer"
                class="row g-0 justify-content-between align-items-start w-100"
              >
                {{ element.title }}
                <svg aria-hidden="true">
                  <use href="/static/img/svg/custom-icons.svg#chevron"></use>
                </svg>
              </a>
            </div>
          </template>
        </draggable>

        <modal
          ref="modal"
          :valid="valid"
          title="Add myLink"
          confirm="Add"
          @modal:confirm="modalConfirm"
        >
          <div>
            <p>Make URLs into myLinks to get to your favorite resources faster.</p>
            <label for="formCustomLinkTitle" class="form-label">Title</label>
            <input
              id="formCustomLinkTitle"
              v-model="customLink.title"
              type="text"
              class="form-control"
              placeholder="ex: Moderna Public Site"
            />

            <label for="formCustomLinkUrl" class="form-label mt-3">URL</label>
            <input
              id="formCustomLinkUrl"
              v-model="customLink.url"
              type="text"
              class="form-control"
              placeholder="ex: https://www.modernatx.com"
              aria-describedby="formCustomLinkUrl-addon"
            />
          </div>
        </modal>
      </div>
      <div class="mylink-add" @click="addCustomLink">
        <svg aria-hidden="true" role="img">
          <use href="/static/img/svg/custom-icons.svg#plus-circle"></use>
        </svg>
        Add myLink
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";

export default {
  name: "MyCorner",
  replace: false,
  components: {
    draggable,
  },
  data() {
    return {
      enabledApps: false,
      enabledPageLinks: false,
      enabledCustomLinks: false,
      dragging: false,
      customLink: { title: "", url: "" },
      customLinkIndex: null,
    };
  },
  computed: {
    apps: {
      get() {
        return this.$store.state.apps;
      },
      set(value) {
        this.$store.commit("SET_APPS", value);
      },
    },
    links: {
      get() {
        return this.$store.state.links;
      },
      set(value) {
        this.$store.commit("SET_LINKS", { links:value, linkType: "links" });
      },
    },
    customLinks: {
      get() {
        return this.$store.state.customLinks;
      },
      set(value) {
        this.$store.commit("SET_LINKS", { links:value, linkType: "customLinks" });
      },
    },
    valid() {
      return (
        this.customLink &&
        this.customLink.title.length > 0 &&
        this.customLink.url.length > 0
      );
    },
  },
  created() {
    this.$store.dispatch("fetchLinks");
    this.$store.dispatch("fetchLinks", "links");
    this.$store.dispatch("fetchLinks", "customLinks");
    this.$store.dispatch("fetchApps").then((apps) => {
      this.apps = apps;
    });
  },
  methods: {
    saveOrderApps() {
      this.$store.dispatch("updateAppsOrder", this.apps);
    },
    saveOrderPageLinks() {
      this.$store.dispatch("updatePageLinks", { links: this.links });
    },
    saveOrderCustomLinks() {
      this.$store.dispatch("updateCustomLinks", { links: this.customLinks });
    },
    addCustomLink() {
      if (!this.isLocked) {
        this.customLink = { title: "", url: "" };
        this.$refs.modal.show();
      } else {
        window.scrollTo(0, 0);
      }
    },
    editCustomLink(link) {
      if (!this.isLocked) {
        this.customLinkIndex = this.customLinks.indexOf(link);
        this.customLink = {
          title: link.title,
          url: link.url,
        };
        this.$refs.modalEdit.show();
      } else {
        window.scrollTo(0, 0);
      }
    },
    deleteCustomLink(link) {
      this.$store.dispatch("deleteLink", { link });
    },

    deleteAppLink(app){
      this.$store.dispatch("putApp", app.id)
    },

    modalConfirm() {
      this.$store.dispatch("putLink", {
        link: this.customLink,
        linkType: "customLinks"
      });
    },
    modalConfirmEdit(link) {
      this.customLinks[this.customLinkIndex] = this.customLink;
      this.$store.dispatch("updateCustomLinks", { links: this.customLinks });
    },
  },
};
</script>
<style scoped>
.ghost {
  opacity: 0.5;
  background: #c3ebfc;
}

.not-draggable {
  cursor: no-drop;
}
</style>
