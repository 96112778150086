<template>
  <!-- eslint-disable -->
  <div class="app-library">
    <div class="container" v-if="appFavorites">
      <div
        v-for="(category, index) in filteredCategories"
        :key="index"
        class="app-category"
      >
        <h2 class="mb-3">{{ category.title }}</h2>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-4">
          <div
            v-for="(app, appIndex) in searchFilter(appByCategory(category))"
            :key="appIndex"
            class="col"
          >
            <div class="card h-100">
              <div class="card-header">
                <div class="row justify-content-between align-items-center">
                  <div class="col me-auto app-logo">
                    <img
                      class="img-fluid"
                      :src="app.icon.url"
                      :width="app.icon.width"
                      :height="app.icon.height"
                      :alt="app.title"
                    />
                  </div>
                  <bookmark-toggle class="col-auto" btn-txt="" :appid="app.id" :is-active="isBookmarked(app.id)"></bookmark-toggle>
                </div>
              </div>
              <div class="card-body">
                <h5 class="card-title visually-hidden">{{ app.title }}</h5>
                <div v-html="app.short_description"></div>
              </div>
              <div class="card-footer">
                <a v-if="app.launch_url" :href="app.launch_url"
                   class="btn launch-btn"
                   target="_blank"
                   rel="noopener noreferrer">
                  Launch
                </a>
                <a v-else href="#PAGEURL" class="btn learnmore-btn">Learn More</a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/runtime-dom";

import { useStore } from "vuex";
import { computed } from "vue";
import BookmarkToggle from "./BookmarkToggle";

export default {
  name: "AppLibrary",
  components: { BookmarkToggle },
  setup() {
    const store = useStore();

    store.dispatch("fetchApps");

    /**
     * @type {Array} app categories from json_script filter.
     */
    const categories = reactive(
      JSON.parse(document.getElementById("app_categories").textContent)
    );

    const appFavorites = computed(() => {
      return store.state.apps;
    });

    const selectedCategories = computed(() => store.state.appFilters);
    const search = computed(() => store.state.appSearch);

    const apps = JSON.parse(
      document.getElementById("user_apps_data").textContent
    );

    const filteredCategories = computed(() => {
      let result = categories;
      if (selectedCategories.value.length > 0) {
        result = categories.filter(
          (category) => selectedCategories.value.indexOf(category.id) >= 0
        );
      }

      return result.filter(
        (category) => searchFilter(appByCategory(category)).length > 0
      );
    });

    const appByCategory = (category) => {
      return apps.filter((app) => app.app_type.title === category.title);
    };

    const searchFilter = (apps) => {
      if (search.value) {
        return apps.filter((app) =>
          app.title.toLowerCase().includes(search.value.toLowerCase())
        );
      }
      return apps;
    };

    const isBookmarked = (appid) => {
      return appFavorites.value.map((a) => a.id).includes(appid);
    };

    return {
      // Refs
      categories,
      apps,
      filteredCategories,
      appFavorites,

      // Methods
      searchFilter,
      appByCategory,
      isBookmarked,
    };
  },
};
</script>

<style scoped></style>
