<template>
  <div class="search-page--results">
    <search-results
        title="Sharepoint"
        :results="sharepointResults"
        :searching="searching"
        :icon="icon"
    ></search-results>
  </div>
</template>

<script>
import SharepointSearchMixin from "../mixins/sharepointSearch";
import SearchResults from "./SearchResults";

export default {
  name: "SharepointSearchResults",
  components: {SearchResults},
  mixins: [SharepointSearchMixin],
  props: {
    icon: {
      type: String,
      required: true,
    },
    query: {
      type: String,
      default: undefined,
    },
  },
  async created() {

    if (this.query) {
      await this.searchSharePoint();
    }
  },
};
</script>

<style scoped></style>
