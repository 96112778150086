<template>
  <div class="msal-login">
    <button @click="signIn">Sign In</button>
    <button @click="search">Search</button>
    <div v-if="rows">
      <ul>
        <li v-for="row in rows">
          {{ row.Title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as msal from "@azure/msal-browser";

export default {
  name: "MsalLogin",

  props: {
    homeAccountID: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      myMSALObj: {},
      account: {},
      loginRequest: {},
      rows: [],
    };
  },

  created() {
    this.loginRequest = {
      // @see https://stackoverflow.com/questions/38571840/office365-sharepoint-scopes
      // scopes: ["https://sealworks.sharepoint.com/AllSites.Read"],
      // scopes: ["https://distributedlogiccorp.sharepoint.com/.default"],
      scopes: ["https:///modernatherapeutics.sharepoint.com/.default"],
    };

    const msalConfig = {
      auth: {
        // clientId: "608193eb-8a0d-42d6-b66b-5c0862f7cd88",
        // clientId: "2114238c-0dec-4908-ba99-b6c307811ac8",
        clientId: "c2721746-4725-41c8-9b85-dc978be57e9b",
        authority:
          // "https://login.microsoftonline.com/b4782755-f4d0-4b54-9821-f3735a2ba2dd",
          // "https://login.microsoftonline.com/distributedlogic.com",
          "https://login.microsoftonline.com/modernatx.com",
        // redirectUri: "http://localhost:8000",
        redirectUri: "https://stg.mymoderna.com",
      },
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        secureCookies: false, // TODO: make configurable per environment
      },
    };

    this.myMSALObj = new msal.PublicClientApplication(msalConfig);

    const currentAccount = this.myMSALObj.getAllAccounts();

    console.log(currentAccount);
  },

  methods: {
    async search() {
      const currentAccount = this.myMSALObj.getAllAccounts()[0];
      console.log("currentAccount", currentAccount);

      if (currentAccount) {
        const response = await this.getTokenPopup(
          this.loginRequest,
          currentAccount
        ).catch((error) => {
          console.log(error);
        });
        console.log("response.accessToken", response.accessToken);

        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;

        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;odata=verbose");
        headers.append("Accept", "application/json;odata=nometadata");

        const postData = {
          request: {
            Querytext: "Nick",
            RowLimit: 100,
            SelectProperties: {
              results: ["Title", "Path"],
            },
            ClientType: "ContentSearchRegular",
            //PersonalizationData: guid
          },
        };
        const options = {
          method: "POST",
          headers: headers,
          body: JSON.stringify(postData),
        };

        // fetch("https://sealworks.sharepoint.com/_api/search/postquery", options)
        // fetch("https:///distributedlogiccorp.sharepoint.com/_api/search/postquery", options)
        fetch("https:///modernatherapeutics.sharepoint.com/_api/search/postquery", options)
          .then((response) => response.json())
          .then((data) => {
            console.log(data);

            const result = [];

            for (const row of data.PrimaryQueryResult.RelevantResults.Table
              .Rows) {
              const obj = {};
              for (const cell of row.Cells) {
                obj[cell.Key] = cell.Value;
              }
              result.push(obj);
            }
            this.rows = result;
          });
      }
    },

    async getTokenPopup(request, account) {
      request.account = account;
      return await this.myMSALObj
        .acquireTokenSilent(request)
        .catch(async (error) => {
          console.log("silent token acquisition fails.");
          if (error instanceof msal.InteractionRequiredAuthError) {
            console.log("acquiring token using popup");
            return this.myMSALObj.acquireTokenPopup(request).catch((error) => {
              console.error(error);
            });
          } else {
            console.error(error);
          }
        });
    },

    signIn() {
      this.myMSALObj
        .loginPopup(this.loginRequest)
        .then((loginResponse) => {
          console.log("id_token acquired at: " + new Date().toString());
          console.log(loginResponse);

          if (this.myMSALObj.getAllAccounts()) {
            this.account = this.myMSALObj.getAllAccounts()[0];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped></style>
