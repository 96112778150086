<template>
  <div
    tabindex="0"
    class="add-btn"
    :active="active || null"
    @click="toggleActive"
  >
    <svg>
      <use :href="icon" />
    </svg>
    <span :hidden="!btnTxt || null">{{ btnTxt }}</span>
  </div>
</template>

<script>
import { computed, defineComponent, toRef } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "BookmarkToggle",
  props: {
    iconUrl: {
      type: String,
      default: "/static/img/svg/custom-icons.svg",
    },
    appid: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
    },
    btnTxt: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();
    const active = toRef(props, "isActive");
    const icon = computed(() => {
      return !active.value
        ? `${props.iconUrl}#heart`
        : `${props.iconUrl}#heart-fill`;
    });

    const toggleActive = () => {
      active.value = !active.value;
      store.dispatch("putApp", props.appid);
    };

    return {
      icon,
      active,

      // Methods
      toggleActive,
    };
  },
});
</script>

<style scoped></style>
