import { mapGetters } from "vuex";

const SharepointSearchMixin = {
  props: {
    serverUrl: {
      type: String,
      required: true,
    },
    rowLimit: {
      type: [Number, String],
      default: 100,
    },
  },

  computed: {
    ...mapGetters(["msalSignedIn", "msalAccessToken"]),
  },
  data() {
    return {
      searching: false,
      sharepointResults: [],
    };
  },
  methods: {
    async searchSharePoint() {
      const signedIn = await this.$store.dispatch("msalSilentAuth");

      if (!signedIn) {
        this.searching = false;
        this.sharepointResults = [];
      }

      const propertyMap = {
        Title: "title",
        Path: "url",
        HitHighlightedSummary: "description",
      };

      const headers = new Headers();
      const bearer = `Bearer ${this.msalAccessToken}`;

      headers.append("Authorization", bearer);
      headers.append("Content-Type", "application/json;odata=verbose");
      headers.append("Accept", "application/json;odata=nometadata");

      const postData = {
        request: {
          Querytext: this.query,
          RowLimit: this.rowLimit,
          ClientType: "custom",
          SelectProperties: {
            results: Object.keys(propertyMap),
          },
          QueryTemplate: "{searchterms} contentclass:STS_ListItem_* Size>0",
        },
      };
      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(postData),
      };

      this.searching = true;

      fetch(`${this.serverUrl}/_api/search/postquery`, options)
        .then((response) => response.json())
        .then((data) => {
          const result = [];

          if (data.PrimaryQueryResult) {
            for (const row of data.PrimaryQueryResult.RelevantResults.Table
              .Rows) {
              const obj = {};
              for (const cell of row.Cells) {
                if (cell.Key in propertyMap) {
                  let value = cell.Value;
                  let propertyName = propertyMap[cell.Key];
                  if (cell.Key === "HitHighlightedSummary" && value) {
                    value = value
                      .replaceAll("c0", "strong")
                      .replaceAll("<ddd/>", "&#8230;");
                  }
                  obj[propertyName] = value;
                }
              }
              result.push(obj);
            }
          }
          this.sharepointResults = result;
          this.searching = false;
        });
    },
  },
};

export default SharepointSearchMixin;
