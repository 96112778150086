<template>
  <div class="search-page--results">
    <div class="col-md-auto">
      <p class="search-page--type">{{ title }}</p>
    </div>
    <div v-if="!searching && results.length > 0" class="col">
      <a
        v-for="(result, index) in results"
        :key="index"
        :href="result.url"
        target="_blank"
        rel="noopener noreferrer"
        class="search-page--link"
      >
        <div class="col-auto">
          <svg>
            <use :href="icon" />
          </svg>
        </div>
        <div class="col">
          <h5>{{ result.title }}</h5>
          <div v-if="result.description" v-html="result.description"></div>
        </div>
      </a>
    </div>
    <div v-if="!searching && results.length === 0" class="col">
      <h6>No results found.</h6>
    </div>
    <div v-if="searching" class="col">
      <h6>Searching ...</h6>
    </div>
  </div>
</template>
<script>
export default {
  name: "SearchResults",
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    results: {
      type: Array,
      default: () => [],
    },
    searching: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
