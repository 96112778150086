import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: "/apps/api",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  async toggleLink(link) {
    return await apiClient.put(`/user-links/`, link);
  },

  async fetchLinks() {
    const { data } = await apiClient.get(`/user-links/`);
    return data;
  },

  async updatePageLinks(links) {
    const { data } = await apiClient.patch("/user-links/", links);
    return data;
  },

  async fetchApps() {
    const { data } = await apiClient.get("/user-apps-list/");
    return data;
  },

  async toggleApp(appid) {
    const { data } = await apiClient.put("/user-apps/", {
      bookmarkActive: appid,
    });
    return data;
  },

  async updateAppOrder(apps) {
    const { data } = await apiClient.patch(
      "/user-apps/",
      apps.map((app) => app.id)
    );
    return data;
  },

  async setMyCorner(displayMyCorner) {
    const { data } = apiClient.put("/mycorner/", {
      state: displayMyCorner,
    });
    return data;
  },
  async fetchMyCorner() {
    const { data } = await apiClient.get("/mycorner/");
    return data;
  },
  async putClicks(clicks) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const { data } = await axios.put("/reporting/api/track/", clicks, {
      headers,
    });
    return data;
  },
  async setHomeAccountId(homeAccountId) {
    const { data } = await apiClient.put("/msal-auth/", {
      homeAccountId: homeAccountId,
    });
    return data;
  },
};
