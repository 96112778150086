<template>
  <div class="breakout">
    <form ref="form" class="input-group" action="/search/" method="GET">
      <input
          id="search-input"
          v-model="query"
          class="form-control"
          type="search"
          name="query"
          placeholder="What are you looking for?"
          aria-label="Search..."
          aria-describedby="search-input-button"
          autocomplete="off"
      />

      <button
          id="search-input-button"
          class="btn btn-outline-secondary"
          type="submit"
          @click="startSearch"
      >
        <svg>
          <use href="/static/img/svg/custom-icons.svg#magnify"/>
        </svg>
        Search
      </button>

      <ul
          id="search-quicksearch"
          class="dropdown-menu dropdown-quicksearch"
          :class="{ show: showDropDown }"
          aria-labelledby="search-input"
      >
        <div class="dropdown-quicksearch--scroll">
          <li class="dropdown-header">
            <p>myModerna</p>
          </li>
          <li v-for="(row, index) in results" :key="index" class="two-columns">
            <a class="dropdown-item" :href="row.href" target="_blank">
              <div>{{ row.title }}</div>
              <p v-if="row.category == 'App'" class="dropdown-text">
                <span class="badge rounded-pill">Launch App</span>
              </p>
              <p v-else class="dropdown-text">{{ row.category }}</p>
            </a>
          </li>

          <li v-show="!msalAccessToken" class="mb-0">
            <button
                class="dropdown-item btn-sharepoint"
                @click.prevent="signIn"
            >
              Add SharePoint Results
            </button>
          </li>
          <li v-if="sharepointResults.length" class="dropdown-header mt-2">
            <p>SharePoint</p>
          </li>
          <li v-for="(row, index) in sharepointResults" :key="index">
            <a class="dropdown-item" :href="row.url">{{ row.title }}</a>
          </li>

        </div>
        <li>
          <a
              class="dropdown-item btn-all-results"
              :href="searchUrlQuery"
              @click="startSearch"
          >View All Results</a
          >
        </li>
      </ul>
    </form>
  </div>
</template>
<script>
import SharepointSearchMixin from "../mixins/sharepointSearch";

export default {
  name: "MySearch",
  replace: false,
  mixins: [SharepointSearchMixin],
  props: {
    searchUrl: {
      type: String,
      default: "/search/",
    },
  },
  data() {
    return {
      query: "",
      results: [],
      resultsVisible: true,
      sharepointResults: [],
    };
  },
  computed: {
    searchUrlQuery() {
      return this.searchUrl + "?query=" + this.query;
    },
    showDropDown() {
      return this.query || this.results.length || this.sharepointResults.length;
    },
  },
  watch: {
    query() {
      this.searchQuery();
      this.searchSharePoint();
    },
  },
  methods: {
    async signIn() {
      if (!this.msalAccessToken) {
        await this.$store.dispatch("msalAuth");
        await this.searchSharePoint();
      }
    },

    async startSearch(e) {
      e.preventDefault();
      if (!this.msalAccessToken) {
        await this.$store.dispatch("msalAuth");
      }
      this.$refs["form"].submit();
    },
    async searchQuery() {
      const response = await fetch("/search/", {
        method: "POST",
        headers: {
          "X-CSRFToken": this.$cookie.getCookie("csrftoken"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({query: this.query}),
      });
      this.results = await response.json();
    },
  },
};
</script>
