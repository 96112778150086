<template>
  <div class="filter-bar">
    <input
      v-model="search"
      type="text"
      placeholder="Which app are you searching for?"
      class="filtering-input"
    />
    <div class="filtering-pills">
      <a
        class="pill"
        tabindex="0"
        :active="allSelected ? true : null"
        @click="toggleAllSelected"
        >All Apps</a
      >
      <a
        v-for="cat in categories"
        :key="cat.id"
        tabindex="0"
        :data-title="cat.title"
        :active="selectedCategories.indexOf(cat.id) >= 0 ? true : null"
        class="pill"
        @click="select(cat)"
        >{{ cat.title }}</a
      >
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "FilterBar",
  replace: false,
  setup() {
    const store = useStore();

    /**
     * @type {Array} app categories from json_script filter.
     */
    const categories = reactive(
      JSON.parse(document.getElementById("app_categories").textContent)
    );

    const selectedCategories = computed(() => store.state.appFilters);

    const search = computed({
      get: () => store.state.appSearch,
      set: (val) => {
        store.commit("SET_APP_SEARCH", val);
      },
    });

    const allSelected = computed(() => {
      return selectedCategories.value.length === categories.length;
    });

    const select = (category, selected = false) => {
      store.dispatch("toggleFilterCategory", {
        id: category.id,
        selected,
      });
    };

    const toggleAllSelected = () => {
      categories.map((cat) => select(cat, true));
    };

    return {
      categories,
      search,

      select,
      toggleAllSelected,

      allSelected,
      selectedCategories,
    };
  },
});
</script>
