<template>
  <div class="container">
    Display:
    <a  class="bubble" tabindex="0" @keyup="allApps" @click="allApps" :active="allAppsAcitve || null">All</a>
    <template v-for="cat in myCats" :key="cat.id">
      <a  tabindex="0" @keyup="toggleApp" @click="toggleApp" :data-slug="cat.slug" :active="cat.active || null" class="bubble">{{cat.title}}</a>
    </template>
  </div>
</template>
<script>
export default {
  name: 'filter-bar-new-hire',
  replace: false,
  props: {
    categories: {
        type: Array
    },
    newHireIndex: {
        type: String
    }
  },
  data(){
    return {
      myCats: [],
      allAppsAcitve: true
    }
  },
  created () {
    this.myCats = this.categories;
    let catFromHash = window.location.hash.substr(1);
    if (catFromHash) {
      //if anchor in url - override localstorage
      this.myCats.forEach((item, idx)=>{
        let itemToMutate = this.myCats[idx]
        if(itemToMutate.slug === catFromHash){
          itemToMutate.active = !itemToMutate.active;
        }
      });
    } else {
      //de-duplicate
      let storedFilters = JSON.parse(localStorage.getItem('newHireFilters-'+this.newHireIndex)) || [];
      this.mergeByProperty(this.myCats,storedFilters, 'id');
    }
    requestAnimationFrame(()=>{this.updateDomManualy()});
  },
  methods: {
    mergeByProperty(target, source, prop){
      source.forEach(sourceElement => {
        let targetElement = target.find(targetElement => {
          return sourceElement[prop] === targetElement[prop];
        })
        targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
      })
    },
    allApps(event){
      if(event.code !== "Enter" && event.code !== undefined){return}

      this.myCats.forEach((cat, idx)=>{
        cat = this.myCats[idx]
        cat.active = false;
      });

      localStorage.setItem('newHireFilters-'+this.newHireIndex, JSON.stringify(this.myCats));
      this.updateDomManualy();
    },
    toggleApp(event){
      if(event.code !== "Enter" && event.code !== undefined){return}
      let slug = event.target.dataset.slug;
      this.myCats.forEach((item, idx)=>{
        let itemToMutate = this.myCats[idx]
        if(itemToMutate.slug === slug){
          itemToMutate.active = !itemToMutate.active;
        }
      });
      let storage = JSON.stringify(this.myCats);
      localStorage.setItem('newHireFilters-'+this.newHireIndex, JSON.stringify(this.myCats));
      this.updateDomManualy();
    },
    updateDomManualy(){
      let activeCats = this.myCats.filter((cat)=>{
        return cat.active;
      }).length

      if(activeCats === 0){
        console.log('activeCats', activeCats);
        this.myCats.forEach((filter)=>{
          let el = document.querySelector(`#cat${filter.id}`);
          el.removeAttribute('hidden');
        })
        this.allAppsAcitve = true
        return;
      }

      if(activeCats === this.myCats.length){
          this.allAppsAcitve = true
      }else{
        this.allAppsAcitve = false;
      }

      this.myCats.forEach((filter)=>{
        let isActive = filter.active;
        let el = document.querySelector(`#cat${filter.id}`);
        if( isActive === true){
          el.removeAttribute('hidden')
        }else{
          el.setAttribute('hidden', '');
        }
      })
    }
  }
}
</script>
