import { createStore } from "vuex";
import service from "./service.js";
import * as msal from "@azure/msal-browser";

export default createStore({
  mutations: {
    ADD_LINK(state, { link }) {
      state.links.push(link);
    },
    DELETE_LINK(state, { link }) {
      state.links.splice(
        state.links.findIndex((obj) => obj.url === link.url),
        1
      );
    },
    SET_LINKS(state, { links }) {
      state.links = links;
    },

    SET_APPS(state, data) {
      state.apps = data;
    },
    SET_MY_CORNER(state, mycorner) {
      state.displayMyCorner = mycorner;
    },

    SET_APP_SEARCH(state, search) {
      state.appSearch = search;
    },

    SET_FILTER_CATEGORIES(state, categories) {
      state.appFilters = categories;
    },

    TOGGLE_FILTER_CATEGORY(state, { id, selected = false }) {
      let index = state.appFilters.indexOf(id);
      if (index === -1) {
        state.appFilters.push(id);
      } else {
        if (selected) {
          return;
        }
        state.appFilters.splice(index, 1);
      }
    },

    DELETE_FILTER_CATEGORY(state, category) {
      state.appFilters.splice(
        state.appFilters.findIndex((id) => category.id === id),
        1
      );
    },

    // MSAL Authentication
    SET_HOME_ACCOUNT_ID(state, homeAccountId) {
      state.homeAccountId = homeAccountId;
    },
    SET_MSAL_ACCESS_TOKEN(state, accessToken) {
      state.msalAccessToken = accessToken;
    },

    SET_MSAL_CONFIG(state, config) {
      state.msalConfig = {
        auth: {
          //clientId: "c2721746-4725-41c8-9b85-dc978be57e9b",
          //authority: "https://login.microsoftonline.com/modernatx.com",
          clientId: config.MSAL_CLIENT_ID,
          authority: config.MSAL_AUTHORITY,
          redirectUri: config.MSAL_REDIRECT_URI,
        },
        scopes: config.MSAL_AUTH_SCOPES,
        cache: {
          cacheLocation: "localStorage", // This configures where your cache will be stored
          storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
          secureCookies: false, // TODO: make configurable per environment
        },
      };
    },
  },

  actions: {
    toggleMyCorner({ state, commit }) {
      commit("SET_MY_CORNER", !state.displayMyCorner);
      this.dispatch("setMyCorner");
    },
    putLink({ commit }, { link }) {
      return service
        .toggleLink(link)
        .then(() => {
          commit("ADD_LINK", { link });
        })
        .catch((error) => {
          throw error;
        });
    },
    deleteLink({ commit }, { link }) {
      return service
        .toggleLink(link)
        .then(() => {
          commit("DELETE_LINK", { link });
        })
        .catch((error) => {
          throw error;
        });
    },

    async updatePageLinks({ commit }, { links }) {
      return service.updatePageLinks(links).then((links) => {
        commit("SET_LINKS", { links });
      });
    },

    async fetchLinks({ commit }) {
      return service
        .fetchLinks()
        .then((links) => {
          commit("SET_LINKS", { links });
        })
        .catch((error) => {
          throw error;
        });
    },

    putApp({ commit }, appid) {
      return service
        .toggleApp(appid)
        .then((apps) => {
          commit("SET_APPS", apps);
        })
        .catch((error) => {
          throw error;
        });
    },

    async updateAppsOrder({ commit }, apps) {
      return service.updateAppOrder(apps).then(() => {
        service.fetchApps().then((apps) => {
          commit("SET_APPS", apps);
        });
      });
    },

    setFilterCategories({ commit }, categories) {
      commit("SET_FILTER_CATEGORIES", categories);
      window.localStorage.setItem("filters", JSON.stringify(categories));
    },

    toggleFilterCategory({ commit, state }, { id, selected = false }) {
      commit("TOGGLE_FILTER_CATEGORY", { id, selected });
      window.localStorage.setItem("filters", JSON.stringify(state.appFilters));
    },

    removeFilterCategory({ commit, state }, category) {
      commit("DELETE_FILTER_CATEGORY", category);
      window.localStorage.setItem("filters", JSON.stringify(state.appFilters));
    },

    async fetchApps({ commit }) {
      const response = await fetch("/apps/api/user-apps-list/");
      const apps = await response.json();
      commit("SET_APPS", apps);
      return apps;
    },
    fetchMyCorner({ commit }) {
      return service
        .fetchMyCorner()
        .then((displayMyCorner) => {
          commit("SET_MY_CORNER", displayMyCorner);
        })
        .catch((error) => {
          throw error;
        });
    },
    setMyCorner({ state }) {
      return service
        .setMyCorner(state.displayMyCorner)
        .then(() => {
          console.log("put displayMyCorner");
        })
        .catch((error) => {
          throw error;
        });
    },

    setHomeAccountId({ commit }, homeAccountId) {
      return service
        .setHomeAccountId(homeAccountId)
        .then(commit("SET_HOME_ACCOUNT_ID", homeAccountId));
    },

    async msalSilentAuth({ commit, state }) {
      const app = new msal.PublicClientApplication(state.msalConfig);
      const account = app.getAccountByHomeId(state.homeAccountId);
      if (account !== null) {
        let request = {
          account: account,
          scopes: state.msalConfig.scopes,
        };
        const response = await app.acquireTokenSilent(request).catch(() => {
          commit("SET_MSAL_ACCESS_TOKEN", null);
        });
        commit("SET_MSAL_ACCESS_TOKEN", response.accessToken);
        return state.msalAccessToken !== null;
      }
      return false;
    },
    async msalAuth({ state, commit, dispatch }) {
      const app = new msal.PublicClientApplication(state.msalConfig);
      const request = {
        scopes: state.msalConfig.scopes,
      };
      const response = await app.acquireTokenPopup(request);
      const homeAccountID = response.account.homeAccountId;
      await dispatch("setHomeAccountId", homeAccountID);
    },
    async trackClick({ dispatch }, click) {
      let clicks = JSON.parse(sessionStorage.getItem("clicks"));
      if (!clicks) {
        clicks = [];
      }
      clicks.push(click);
      sessionStorage.setItem("clicks", JSON.stringify(clicks));
      dispatch("putClicks");
    },
    async putClicks() {
      const clicks = sessionStorage.getItem("clicks");
      return service
        .putClicks(clicks)
        .then(() => {
          sessionStorage.removeItem("clicks");
        })
        .catch((error) => {
          throw error;
        });
    },
  },
  getters: {
    msalAccessToken: (state) => {
      return state.msalAccessToken;
    },
    msalSignedIn: (state) => {
      return state.msalAccessToken !== null;
    },
  },
  state: {
    apps: [],
    displayMyCorner: true,
    homeAccountId: "",
    msalConfig: {},
    msalAccessToken: null,
    links: [],
    appFilters: initAppFilters(),
    appSearch: "",
  },
});

function initAppFilters() {
  const filters = window.localStorage.getItem("filters");
  if (filters) {
    return JSON.parse(filters);
  }
  return [];
}
