import "../scss/styles.scss";
import "bootstrap";
import { createApp } from "vue";
import BookmarkLink from "./components/BookmarkLink.vue";
import MyCorner from "./components/MyCorner.vue";
import FilterBar from "./components/FilterBar.vue";
import FilterBarNewHire from "./components/FilterBarNewHire.vue";
import MySearch from "./components/MySearch.vue";
import Modal from "./components/Modal.vue";
import store from "./store";
import { VueCookieNext } from "vue-cookie-next";
import MsalLogin from "./components/MsalLogin";
import SharepointSearchResults from "./components/SharepointSearchResults";
import SearchResults from "./components/SearchResults";
import AppLibrary from "./components/AppLibrary";

// eslint-disable-next-line vue/one-component-per-file
const app = createApp({
  computed: {
    myCornerOpen() {
      return this.$store.state.displayMyCorner;
    },
  },
  async created() {
    this.$store.commit(
      "SET_MSAL_CONFIG",
      JSON.parse(document.getElementById("_SHAREPOINT_SETTINGS_").textContent)
    );

    console.log(
      "_MSAL_HOME_ACCOUNT_ID_",
      JSON.parse(document.getElementById("_MSAL_HOME_ACCOUNT_ID_").textContent)
    );

    this.$store.commit(
      "SET_HOME_ACCOUNT_ID",
      JSON.parse(document.getElementById("_MSAL_HOME_ACCOUNT_ID_").textContent)
    );

    await this.$store.dispatch("msalSilentAuth");
  },
  mounted: function () {
    window.document.addEventListener("click", this.onClickVue);
    this.$store.dispatch("putClicks"); // send any unsent clicks to API
  },
  methods: {
    toggleMyCorner() {
      document.getElementById("gridWrapper").classList.toggle("mycorner--open");
      document
        .getElementById("gridWrapperNav")
        .classList.toggle("mycorner--open");
      this.$store.dispatch("toggleMyCorner");
    },
    onClickVue(e) {
      //console.log("this is the click tracker", e.target);
      if (e.target && e.target.getAttribute("href") !== "#") {
        const target_url = event.target.closest("a");

        if (target_url) {
          let source = window.location.pathname;
          if (
            this.checkParent(
              document.getElementById("gridWrapperNav"),
              e.target
            )
          ) {
            source = "TopNav";
          }
          if (
            this.checkParent(document.getElementById("mycorner_body"), e.target)
          ) {
            source = "MyCorner";
          }
          if (
            this.checkParent(document.getElementById("search-app"), e.target)
          ) {
            source = "QuickSearch";
          }
          console.log("source", source);
          console.log(
            "target_url is getting saved attribute",
            target_url.getAttribute("href")
          );
          const click = {
            source_url: source,
            target_url: target_url.getAttribute("href"),
          };
          this.$store.dispatch("trackClick", click);
        }
      }
    },
    checkParent(parent, target) {
      if (parent.contains(target)) return true;
      return false;
    },
  },
})
  .use(VueCookieNext)
  .use(store);
app.config.devtools = true;
app.component("BookmarkLink", BookmarkLink);
app.component("MyCorner", MyCorner);
app.component("Modal", Modal);
app.component("FilterBar", FilterBar);
app.component("AppLibrary", AppLibrary);
app.component("FilterBarNewHire", FilterBarNewHire);
app.component("MsalLogin", MsalLogin);
app.component("SharepointSearchResults", SharepointSearchResults);
app.mount("#app");

// eslint-disable-next-line vue/one-component-per-file
const searchApp = createApp({}).use(VueCookieNext).use(store);
searchApp.config.devtools = true;
searchApp.component("MySearch", MySearch);
searchApp.component("SearchResults", SearchResults);
searchApp.mount("#search-app");

// BS5 carousel
let items = document.querySelectorAll(".carousel .carousel-item");
items.forEach((el) => {
  const minPerSlide = 3;
  let next = el.nextElementSibling;
  for (var i = 1; i < minPerSlide; i++) {
    if (!next) {
      // wrap carousel by using first child
      next = items[0];
    }
    let cloneChild = next.cloneNode(true);
    el.appendChild(cloneChild.children[0]);
    next = next.nextElementSibling;
  }
});

/////////////////////////////////
// Scott searchtest below here //
/////////////////////////////////

// Michael: this is ONLY a FPO toggle for quicksearch. PLEASE REMOVE!
//document.getElementById("search-input").addEventListener("input", displayQuickSearch);
//function displayQuickSearch() {
//document.getElementById("search-quicksearch").classList.toggle("show");
//}
