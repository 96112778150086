<template>
  <div ref="modal" class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">{{ title }}</h3>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <button
            :disabled="!valid"
            type="button"
            class="assessment-btn-primary"
            @click="modalConfirm"
          >
            {{ confirm }}
          </button>
          <button
              type="button"
              class="assessment-btn-secondary"
              data-bs-dismiss="modal"
              @click="$emit('modal:cancel')"
          >
            {{ cancel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  name: "Modal",
  props: {
    title: {
      type: String,
      required: true,
    },
    cancel: {
      type: String,
      default: "Cancel",
    },
    confirm: {
      type: String,
      default: "Continue",
    },
    valid: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      modal: null,
    };
  },
  mounted() {
    this.modal = new Modal(this.$refs.modal);
  },
  methods: {
    show() {
      this.modal.show();
    },
    modalConfirm() {
      this.modal.hide();
      this.$emit("modal:confirm");
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped></style>
